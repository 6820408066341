.ant-table-row {
  &.highlighted {
    .ant-table-cell {
      background: #fae0e0d9;

      &.ant-table-cell-row-hover {
        background: #fae0e0;
      }
    }
  }
}
