.timetableInfo .ant-descriptions-row th {
  padding: 4px 5px !important;
}

.timetableInfo .ant-descriptions-row td {
  padding: 4px 5px !important;
}

.timetableInfo th.ant-table-cell {
  padding: 4px 5px !important;
}

.timetableInfo .ant-table-row td {
  padding: 4px 5px !important;
}
