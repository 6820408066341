$block: 'application-layout';

.#{$block} {
  height: 100vh;

  &__header {
    display: flex;
    justify-content: flex-end;
    background: #fff !important;
    border-left: 1px solid #eee;
  }

  &__content {
    padding: 1em;
    overflow-y: scroll;
  }
}
