@import '~antd/dist/antd.css';

body[theme='base'] {
  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 600;
  }
}


.ant-modal-close-x {
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.ant-picker-time-panel-column:after {
  height: 0;
}

.rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-event {
  border: 1px solid transparent;
}
