$block: 'student-list-filters';

.#{$block} {
  display: flex;
  width: 100%;

  &__field-container {
    &:empty {
      display: none;
    }
  }

  &__search {
    width: 100%;
  }
}
